import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Tag from 'antd/es/tag';
import {
    DeleteOutlined,
    ReloadOutlined,
    PlusOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { AlignType } from 'rc-table/es/interface';
import dayjs from 'dayjs';
import relTime from 'dayjs/plugin/relativeTime';

import api from '../api';
import auth from '../auth';
import context from '../context';
import text from './text';
import loading from './loading';

dayjs.extend(relTime);

export namespace ssh {
    /**
     * SSH keys are long, and the leading bits look the same. The characters
     * at the end are those that help differentiate one key from another.
     * So this function returns a short, display friendly version of the key
     * that displays enough information for the user to (hopefully) identify
     * one key from another.
     */
    function shortKey(k: string) {
        // SSH keys have several parts, space-delimitered. They start with a
        // readable string identifying the algorithm. Then the public key,
        // and sometimes an email address and/or name for the associated
        // identity.
        //
        // To display a useful, short version of the key we start by splitting
        // on the space character.
        const parts = k.split(/\s+/);

        // If the key doesn't match the format we expect, just return the first
        // few characters.
        if (parts.length < 2) {
            return `${k.slice(0, 8)}…`;
        }
        const [algo, key, identity] = parts;
        const shortKey =
            key.length > 12 ? `${algo} ${key.substr(0, 3)}…${key.substr(key.length - 9)}` : key;
        if (!identity) {
            return shortKey;
        }
        return `${shortKey} ${identity}`;
    }

    const LastFormItem = styled(Form.Item)`
        ${({ theme }) => `
            margin-bottom: ${theme.spacing.md};
        `}
    `;

    const ButtonRow = styled.div`
        display: flex;
        justify-content: flex-end;
    `;

    const SaveButton = styled(Button)`
        min-width: 100px;
    `;

    const HelpText = styled.div`
        ${({ theme }) => `
            margin: 0 0 ${theme.spacing.md};
            padding: ${theme.spacing.md};
            background: ${theme.palette.background.warning};
            border-bottom: 4px solid ${theme.palette.border.warning};

            p {
                margin: 0 0 ${theme.spacing.sm};

                &:last-child {
                    margin: 0;
                }
            }
        `}
    `;

    const exampleKey = `ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQCtzAhV8IXRjjtQKFxEyon1MRonEpfLDKDBa0lyNKo+ZnynGh5NW/IYHaA6LVpLH6b6Y6xX8XjTS8oBUxxiM9tkBd4foTpOxYhLx+UrmPKHe7OA6EnKhd6fOxrN4xjfMgT37rVCfK4TzdKFKAjWpbjnFgPuJ2FP3QQNNPic3lNrZ6WyTgpE3dtenLIUUsMIUR6UXXaJ/2OdIWMdMQQzGMfs/+UQmE3m9k6SJW2zreLsA5+/QopNGmabkhVYLgJuRL1PAGyIUt52VeJcblnY306OsvN5DNu9b9tx11Qleg5+zfFe7gTdBwsulZngbPf7u+CPVzBoQEMY+4++IXT6UQMRqODf9t3eDMtwwHgv6Uksq6IOFppgp5Jg5Wuk3D9iFhNqJj1uhMwBxuaLNKdg3kQT4PMvUggV1Z0Qx52veTvI4CQNLwBWqpKAHQwnHwgYbAOxrwJGcq714G2Oqo9/KrkrwhzOMMZ61iHUjVkdbu4aQd4NDAvT3IGZEIwP54XKRm6GEQgwau+DyrWOcmyGIxBfSd/ohrhFE5zlA33eQGDTFXo0h45R9dgZF/6skVrBonP/n9435Hf8Dstp5yJAXgQnYDglub2pK9DAFcnhm09o5iqDqLnmLCVzO7mq5ja/lTBDio0yWu3CkzSZmubC1LDrQ++rjt5bWBI+rRsdYlKuvQ== whoami@allenai.org`;
    const SSHKeyInput = styled(Input.TextArea).attrs(() => ({
        rows: 12,
        placeholder: exampleKey,
    }))`
        word-break: break-all;
    `;

    function AddPublicKeyForm({ onKeyAdded }: { onKeyAdded: () => void }) {
        const [submitting, setSubmitting] = useState(false);
        const { user } = useContext(context.LoggedInUser);
        if (!user) {
            auth.Login();
            return null;
        }

        return (
            <Form<{ key: string }>
                layout="vertical"
                requiredMark={false}
                onFinish={({ key }) => {
                    setSubmitting(true);
                    api.AddPublicSSHKey(user.username, key)
                        .then(() => {
                            message.success('Key added');
                            onKeyAdded();
                        })
                        .catch((err) => {
                            console.error('adding key', err);
                            if (api.IsUnauthorized(err)) {
                                auth.RefreshExpiredLogin();
                                return;
                            }
                            message.error(`Failed to add key: ${err.response.data}`);
                            setSubmitting(false);
                        });
                }}>
                <HelpText>
                    <p>
                        <strong>Copy and paste your public key into the text area below.</strong>
                    </p>
                    <p>
                        SSH keys are comprised of two parts, one that's private and important to be
                        kept confidential, and another that's public and can be broadly shared.
                    </p>
                    <p>
                        <strong>
                            Make sure you're uploading the public portion of your key. Generally the
                            public key is stored in a file with the <code>.pub</code> extension.
                        </strong>
                    </p>
                    <p>
                        By default SSH keys will be stored in <code>~/.ssh</code>.
                    </p>
                    <p>
                        If you'd like to create a new key, follow{' '}
                        <a
                            href="https://docs.github.com/en/authentication/connecting-to-github-with-ssh/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent#generating-a-new-ssh-key"
                            rel="noopener,noreferrer">
                            these instructions.
                        </a>
                    </p>
                    <p>
                        <strong>
                            After creating a new key, it takes 15 minutes to fully propagate. Please
                            wait before trying to login.
                        </strong>
                    </p>
                </HelpText>
                <LastFormItem
                    name="key"
                    rules={[{
                        required: true,
                        validator: (_, value) => {
                            if (/\n/.test(value)) {
                                return Promise.reject();
                            }
                            return Promise.resolve();
                        },
                        message: 'Please enter a valid public key (e.g., no newlines)'
                    }]}>
                    <SSHKeyInput />
                </LastFormItem>
                <ButtonRow>
                    <SaveButton type="primary" htmlType="submit" loading={submitting}>
                        Save
                    </SaveButton>
                </ButtonRow>
            </Form>
        );
    }

    function byFingerprint(a: api.PublicSSHKey, b: api.PublicSSHKey) {
        return a.fingerprint.localeCompare(b.fingerprint);
    }

    const Row = styled.div`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: 1fr min-content;
            align-items: center;
            gap: ${theme.spacing.md};
            margin: 0 0 ${theme.spacing.lg};
        `}
    `;

    // To change some styles we wrap the `<Table />` element in this component.
    // It's hacky, but eh, it works.
    // ¯\_(ツ)_/¯
    const TableStyles = styled.div`
        ${({ theme }) => `
            > .ant-table-wrapper table th {
                font-weight: ${theme.typography.font.weight.bold};
            }

            > .ant-table-wrapper table td {
                background: ${theme.palette.common.white};
            }
        `}
    `;

    const NoWrap = styled.span`
        white-space: nowrap;
    `;

    const StatusBadge = styled(Tag)`
        display: block;
    `;

    const KeyActionsRow = styled.span`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: ${theme.spacing.xs};
            justify-content: center;
        `}
    `;

    enum KeyActionState {
        Idle,
        Renewing,
        Deleting,
    }

    interface KeyActionsProps {
        publicKey: api.PublicSSHKey;
        onAction: () => void;
    }

    export function KeyActions({ publicKey, onAction }: KeyActionsProps) {
        const [state, setState] = useState<KeyActionState>(KeyActionState.Idle);
        const { user } = useContext(context.LoggedInUser);
        if (!user) {
            auth.Login();
            return null;
        }

        return (
            <KeyActionsRow>
                <Tooltip title="Renew Key">
                    <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        size="small"
                        loading={state === KeyActionState.Renewing}
                        disabled={state === KeyActionState.Deleting}
                        onClick={() => {
                            setState(KeyActionState.Renewing);
                            api.RenewPublicSSHKey(user.username, publicKey.fingerprint)
                                .then(() => {
                                    message.success('Key Renewed');
                                    onAction();
                                    setState(KeyActionState.Idle);
                                })
                                .catch((err) => {
                                    console.error('renewing key', err);
                                    if (api.IsUnauthorized(err)) {
                                        auth.RefreshExpiredLogin();
                                        return;
                                    }
                                    message.error('Failed to renew key. Please try again.');
                                    setState(KeyActionState.Idle);
                                });
                        }}
                    />
                </Tooltip>
                <Tooltip title="Permanently Delete Key">
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        title="Delete"
                        size="small"
                        danger
                        loading={state === KeyActionState.Deleting}
                        disabled={state === KeyActionState.Renewing}
                        onClick={() => {
                            setState(KeyActionState.Deleting);
                            api.DeletePublicSSHKey(user.username, publicKey.fingerprint)
                                .then(() => {
                                    message.success('Key Deleted');
                                    onAction();
                                    setState(KeyActionState.Idle);
                                })
                                .catch((err) => {
                                    console.error('deleting key', err);
                                    if (api.IsUnauthorized(err)) {
                                        auth.RefreshExpiredLogin();
                                        return;
                                    }
                                    message.error('Failed to delete key. Please try again.');
                                    setState(KeyActionState.Idle);
                                });
                        }}
                    />
                </Tooltip>
            </KeyActionsRow>
        );
    }

    /**
     * Each row in a <Table /> needs a valid key, so that React can determine
     * when it needs to update the DOM. See:
     * https://reactjs.org/docs/lists-and-keys.html
     */
    class PublicSSHKeyRow extends api.PublicSSHKey {
        get key() {
            return this.fingerprint;
        }
    }

    export function Keys() {
        const [keys, setKeys] = useState<api.PublicSSHKey[]>();
        const [keysFailedToLoad, setKeysFailedToLoad] = useState(false);
        const [showAddKeyModal, setShowAddKeyModal] = useState(false);
        const { user } = useContext(context.LoggedInUser);
        if (!user) {
            auth.Login();
            return null;
        }

        useEffect(() => {
            api.ListPublicSSHKeys(user.username)
                .then((k) => setKeys(k))
                .catch((err) => {
                    console.error('listing ssh keys', err);
                    if (api.IsUnauthorized(err)) {
                        auth.RefreshExpiredLogin();
                    }
                    setKeysFailedToLoad(true);
                });
        }, []);

        if (!keys && !keysFailedToLoad) {
            return (
                <loading.Centered>
                    <loading.FakeProgressSpinner />
                </loading.Centered>
            );
        }

        if (!keys || keysFailedToLoad) {
            return (
                <loading.Centered>
                    <loading.Error />
                </loading.Centered>
            );
        }

        const columns = [
            {
                title: 'Fingerprint',
                key: 'fingerprint',
                ellipsis: { showTitle: false },
                width: 200,
                dataIndex: 'fingerprint',
                render(f: string) {
                    return (
                        <Tooltip title={f} placement="topLeft">
                            {f}
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Public Key',
                key: 'publicKey',
                dataIndex: 'publicKey',
                render(k: string) {
                    return (
                        <Tooltip title={k}>
                            <NoWrap>{shortKey(k)}</NoWrap>
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Expires',
                key: 'expiry',
                width: '14ch',
                dataIndex: 'expirationTimeUsec',
                render(e: number) {
                    // The value is in microseconds. DayJS takes milliseconds.
                    const milli = e / 1000;
                    const d = dayjs(milli);
                    return <Tooltip title={d.format('YYYY-MM-DD')}>{d.fromNow()}</Tooltip>;
                },
            },
            {
                title: 'Status',
                key: 'expiry',
                width: '13ch',
                dataIndex: 'expirationTimeUsec',
                render(e: number) {
                    const expires = new Date(e / 1000);
                    const today = new Date();
                    if (today < expires) {
                        return (
                            <StatusBadge icon={<CheckCircleOutlined />} color="green">
                                Active
                            </StatusBadge>
                        );
                    }
                    return (
                        <StatusBadge icon={<ExclamationCircleOutlined />} color="red">
                            Expired
                        </StatusBadge>
                    );
                },
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'center' as AlignType,
                width: 100,
                render(key: api.PublicSSHKey) {
                    return (
                        <KeyActions
                            publicKey={key}
                            onAction={() => {
                                api.ListPublicSSHKeys(user.username)
                                    .then((k) => setKeys(k))
                                    .catch((err) => {
                                        console.error('listing keys', err);
                                        if (api.IsUnauthorized(err)) {
                                            auth.RefreshExpiredLogin();
                                        }
                                        setKeysFailedToLoad(true);
                                    });
                            }}
                        />
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <text.Big>
                        <strong>
                            Your SSH keys are shown below. They can be used to connect to AI2 hosts.
                        </strong>
                    </text.Big>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setShowAddKeyModal(true);
                        }}>
                        Add Key
                    </Button>
                </Row>
                <TableStyles>
                    <Table
                        columns={columns}
                        dataSource={keys
                            .sort(byFingerprint)
                            .map(
                                (k) =>
                                    new PublicSSHKeyRow(
                                        k.fingerprint,
                                        k.name,
                                        k.publicKey,
                                        k.expirationTimeUsec,
                                        k.retainUntilTimeUsec
                                    )
                            )}
                        pagination={false}
                    />
                </TableStyles>
                {showAddKeyModal ? (
                    <Modal
                        width={700}
                        title={<strong>Add New SSH Key</strong>}
                        visible={true}
                        onCancel={() => {
                            setShowAddKeyModal(false);
                        }}
                        footer={null}>
                        <AddPublicKeyForm
                            onKeyAdded={() => {
                                api.ListPublicSSHKeys(user.username)
                                    .then((k) => {
                                        setKeys(k);
                                    })
                                    .catch((err) => {
                                        console.error('listing keys', err);
                                        if (api.IsUnauthorized(err)) {
                                            auth.RefreshExpiredLogin();
                                        }
                                        setKeysFailedToLoad(true);
                                    });
                                setShowAddKeyModal(false);
                            }}
                        />
                    </Modal>
                ) : null}
            </>
        );
    }
}

export default ssh;
